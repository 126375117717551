import { default as emailsd5SArHGmreMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/emails.vue?macro=true";
import { default as integrationsOclx0t3kZMMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/integrations.vue?macro=true";
import { default as overviewsrmalQnoqMMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/overview.vue?macro=true";
import { default as indexDbBUGDhn1HMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsXi852GtG6VMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexDfEHPWqoCHMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/structures/index.vue?macro=true";
import { default as indexL8II2MTZYBMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/users/[id]/index.vue?macro=true";
import { default as addFfKuvJ3GexMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/users/add.vue?macro=true";
import { default as index7hADH4mQG3Meta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/users/index.vue?macro=true";
import { default as admin9SzRmEvu7CMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin.vue?macro=true";
import { default as accountZXeO7pLmiCMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/account.vue?macro=true";
import { default as entourage8DU0nRH5BeMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationswcEh45kCAXMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthMjbTPEz7DyMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as info8J9bcWehpAMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationIHOjC8RgSMMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdjif6v8kO5UMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printWO9an9FDsXMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexT7FFI9RZuSMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/index.vue?macro=true";
import { default as editFGJqVmXGntMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editkvtOpBDQ2xMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editJJavgUuXrrMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as add0IurXNl3onMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editS7GP1ENRnXMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addHAJjzAKqDNMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editjU0fBflCbNMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addtnj5SgTF4kMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editwQbf5ojNRbMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexIpdsc1rqJ3Meta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/index.vue?macro=true";
import { default as addvLrvlaJxlbMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/add.vue?macro=true";
import { default as historyTv6bS1bDNKMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/history.vue?macro=true";
import { default as printd316c26H52Meta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewLWY0rLZRiLMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/overview.vue?macro=true";
import { default as partnersFMigkUY1BcMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/partners.vue?macro=true";
import { default as statstiw2mEJmlDMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/stats.vue?macro=true";
import { default as index8jNByoicgGMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/structure/index.vue?macro=true";
import { default as subjectswBAzNxYJVKMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/structure/subjects.vue?macro=true";
import { default as usersE6zqZ7j1rmMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/users.vue?macro=true";
import { default as app2DXjsGfP1EMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app.vue?macro=true";
import { default as adminR7qGAoqfacMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/admin.vue?macro=true";
import { default as login0sZ5TveuelMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/login.vue?macro=true";
import { default as logoutDQ8A2paMzLMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/logout.vue?macro=true";
import { default as proconnectYBLHQSglaIMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/proconnect.vue?macro=true";
import { default as validate_45tokenr39Wk01PXgMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/validate-token.vue?macro=true";
import { default as manageriel2VWF7azMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/cgu/manager.vue?macro=true";
import { default as userzf0dwicLGjMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/cgu/user.vue?macro=true";
import { default as cguhIzV1IvDmDMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitehFBdT7UWIqMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountv3v65r6vziMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45userb5Qh8BEILbMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/error/unknown-user.vue?macro=true";
import { default as indexGZlerqy0h9Meta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/index.vue?macro=true";
import { default as mentions_45legaleseBcvI0O0MvMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialitenSh9pkFuPTMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/politique-confidentialite.vue?macro=true";
import { default as register5PQz7VA8N7Meta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/register.vue?macro=true";
import { default as statistiquesPZaGoYVtBHMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/statistiques.vue?macro=true";
import { default as stories6kzc6f5SggMeta } from "/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: admin9SzRmEvu7CMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overviewsrmalQnoqMMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewsrmalQnoqMMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: app2DXjsGfP1EMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationIHOjC8RgSMMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationIHOjC8RgSMMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printWO9an9FDsXMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: indexT7FFI9RZuSMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexT7FFI9RZuSMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: editFGJqVmXGntMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editFGJqVmXGntMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: editkvtOpBDQ2xMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editkvtOpBDQ2xMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editwQbf5ojNRbMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editwQbf5ojNRbMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printd316c26H52Meta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewLWY0rLZRiLMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewLWY0rLZRiLMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminR7qGAoqfacMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login0sZ5TveuelMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutDQ8A2paMzLMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-proconnect",
    path: "/auth/proconnect",
    meta: proconnectYBLHQSglaIMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/proconnect.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenr39Wk01PXgMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguhIzV1IvDmDMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitehFBdT7UWIqMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountv3v65r6vziMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userb5Qh8BEILbMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexGZlerqy0h9Meta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legaleseBcvI0O0MvMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialitenSh9pkFuPTMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: register5PQz7VA8N7Meta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesPZaGoYVtBHMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: stories6kzc6f5SggMeta || {},
    component: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/pages/stories.vue").then(m => m.default || m)
  }
]