import validate from "/build/8bb4fafa-6993-421f-b671-39582b670e86/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/build/8bb4fafa-6993-421f-b671-39582b670e86/node_modules/.pnpm/nuxt@3.13.0_@types+node@22.5.1_eslint@8.57.0_sass@1.77.8_stylelint@16.9.0_typescript@5.5.4_vite@5.4.2_vue-tsc@2.1.2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/middleware/auth.ts"),
  cgu: () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/middleware/cgu.ts"),
  "skip-auth": () => import("/build/8bb4fafa-6993-421f-b671-39582b670e86/middleware/skip-auth.ts")
}